import i18n from '@/lang/i18n'

export default [
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('dashboard'),
    to: '/dashboard',
    icon: 'cil-speedometer'
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('system_management')]
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('organization_management'),
    route: '/organization',
    icon: 'cil-people',
    items: [
      {
        name: i18n.t('accounts'),
        to: '/organization/admins',
        icon: 'cil-user',
        role: 'root',
        badge: {
          color: 'secondary',
          text: i18n.t('manager')
        }
      },
    ]
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('configs'),
    to: '/configs',
    icon: 'cil-cog',
    role: 'root',
    badge: {
      color: 'secondary',
      text: i18n.t('manager')
    }
  },
  {
    _name: 'CSidebarNavTitle',
    _children: [i18n.t('backend_management')]
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('devices_management'),
    to: '/devices',
    icon: 'cil-mobile',
    role: 'devices'
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('phones_management'),
    route: '/phone_books',
    icon: 'cil-address-book',
    items: [
      {
        name: i18n.t('phone_books'),
        to: '/phone_books/phone_books',
        icon: 'cil-address-book',
        role: 'phone_books-phone_books'
      },
      {
        name: i18n.t('phone_numbers'),
        to: '/phone_books/phones',
        icon: 'cil-dialpad',
        role: 'phone_books-phones'
      },
      {
        name: i18n.t('qualified_customer_number_transfer'),
        to: '/phone_books/qualified',
        icon: 'cil-check',
        role: 'root',
        badge: {
          color: 'secondary',
          text: i18n.t('manager')
        }
      },
    ]
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('messages_management'),
    to: '/messages',
    icon: 'cil-short-text',
    role: 'messages'
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('logs_management'),
    route: '/logs',
    icon: 'cil-history',
    items: [
      {
        name: i18n.t('dialing_logs'),
        to: '/logs/dialing_logs',
        icon: 'cil-phone',
        role: 'logs-dialing_logs'
      },
      {
        name: i18n.t('sending_logs'),
        to: '/logs/sending_logs',
        icon: 'cil-send',
        role: 'logs-sending_logs'
      },
    ]
  },
]
